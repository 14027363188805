@import "/src/styles/index";

.headerWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: $header-height;
  position: sticky;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  z-index: 10;
  grid-column: 1 / span 1;
}

.menuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 23.5px;
  .menuItem {
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: #fff;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
    }
  }
}

.logo {
  height: $header-height;
  // background-color: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    margin-left: 16px;
    object-fit: cover;
  }
}

.email {
  font-size: 16px;
  font-weight: 700;
}

.logout {
  color: #a61f17;
}
