@import "/src/styles/variables.scss";

.pageWrapper {
  height: 100vh;
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: minmax(204px, auto) 1fr;
}
.mainWrapper {
  grid-column: 2 / span 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pageContent {
  max-height: calc(100vh - #{$header-height});
  max-width: calc(100vw - 204px);
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  background: #f4f4f4;
  padding: 16px 16px 16px 40px;
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #b8b8b8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b8b8b8;
  }
}
